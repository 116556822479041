<template>
  <!--
    A form where cameraManagementConfigurations can be edited.
  -->
  <div class="cameraManagementConfigForm">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="readyToDisplay">
        <div class="form-group">
          <label>{{ $t('camera') }}</label>
          <select
            v-model="selectedCamera"
            :class="['form-control', { 'is-invalid': errors.has(`CameraConfigs[${ usedId }].IpAddress`) }]"
          >
            <option
              v-for="(camera, index) in networkCameras"
              :key="`networkCam${ index }`"
              :value="camera"
            >
              {{ camera.manufacturer }} - {{ camera.ipAddress }}
            </option>
          </select>
          <span
            v-show="errors.has(`CameraConfigs[${ usedId }].IpAddress`)"
            class="badge badge-danger"
          >{{ errors.first(`CameraConfigs[${ usedId }].IpAddress`) }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('ipAddress') }}</label>
          <input
            v-model="cameraConfig.ipAddress"
            :class="['form-control', { 'is-invalid': errors.has(`CameraConfigs[${ usedId }].IpAddress`) }]"
            type="text"
          >
          <span
            v-show="errors.has(`CameraConfigs[${ usedId }].IpAddress`)"
            class="badge badge-danger"
          >{{ errors.first(`CameraConfigs[${ usedId }].IpAddress`) }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('manufacturer') }}</label>
          <input
            v-model="cameraConfig.manufacturer"
            :class="['form-control', { 'is-invalid': errors.has(`CameraConfigs[${ usedId }].Manufacturer`) }]"
            type="text"
          >
          <span
            v-show="errors.has(`CameraConfigs[${ usedId }].Manufacturer`)"
            class="badge badge-danger"
          >{{ errors.first(`CameraConfigs[${ usedId }].Manufacturer`) }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('model') }}</label>
          <input
            v-model="cameraConfig.model"
            :class="['form-control', { 'is-invalid': errors.has(`CameraConfigs[${ usedId }].Model`) }]"
            type="text"
          >
          <span
            v-show="errors.has(`CameraConfigs[${ usedId }].Model`)"
            class="badge badge-danger"
          >{{ errors.first(`CameraConfigs[${ usedId }].Model`) }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('username') }}</label>
          <input
            v-model="cameraConfig.username"
            :class="['form-control', { 'is-invalid': errors.has(`CameraConfigs[${ usedId }].Username`) }]"
            type="text"
          >
          <span
            v-show="errors.has(`CameraConfigs[${ usedId }].Username`)"
            class="badge badge-danger"
          >{{ errors.first(`CameraConfigs[${ usedId - 1 }].Username`) }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('password') }}</label>
          <input
            v-model="cameraConfig.password"
            :class="['form-control', { 'is-invalid': errors.has(`CameraConfigs[${ usedId }].Password`) }]"
            type="text"
          >
          <span
            v-show="errors.has(`CameraConfigs[${ usedId }].Password`)"
            class="badge badge-danger"
          >{{ errors.first(`CameraConfigs[${ usedId }].Password`) }}</span>
        </div>
        <div class="form-group">
          <label>Pan / tilt {{ $t('ipAddress').toLowerCase() }}</label>
          <input
            v-model="cameraConfig.panTiltConfig.ipAddress"
            :class="['form-control', { 'is-invalid': errors.has(`CameraConfigs[${ usedId }].PanTiltConfig.IpAddress`) }]"
            type="text"
          >
          <span
            v-show="errors.has(`CameraConfigs[${ usedId }].PanTiltConfig.IpAddress`)"
            class="badge badge-danger"
          >{{ errors.first(`CameraConfigs[${ usedId }].PanTiltConfig.IpAddress`) }}</span>
        </div>
        <div class="form-group">
          <label>CameraOperator Host IP</label>
          <input
            v-model="cameraConfig.cameraOperatorHostIp"
            :class="['form-control', { 'is-invalid': errors.has(`CameraConfigs[${ usedId }].CameraOperatorHostIp`) }]"
            type="text"
          >
          <span
            v-show="errors.has(`CameraConfigs[${ usedId }].CameraOperatorHostIp`)"
            class="badge badge-danger"
          >{{ errors.first(`CameraConfigs[${ usedId }].CameraOperatorHostIp`) }}</span>
        </div>
        <button
          class="btn btn-primary float-right"
          @click="setConfig"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="updateButtonIcon"
            style="vertical-align: middle;"
          />{{ updateButtonText }}
        </button>
        <div class="clearfix" />
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: 'CameraManagementConfigForm',
  mixins: [
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    currentId: {
      type: Number,
      required: false,
      default () {
        return null;
      }
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      usedId: null,
      configData: null,
      selectedCamera: null,
      networkCameras: null,
      loading: true,
      updateButtonText: this.$t('update'),
      updateButtonIcon: 'upload'
    }
  },
  computed: {
    cameraConfig () {
      if (this.configData == null) {
        return null;
      }
      if (this.configData.cameraConfigs == null) {
        return null;
      }
      return this.configData.cameraConfigs.find(o => {
        return o.id === this.usedId
      });
    },
    readyToDisplay () {
      if (this.configData == null) {
        return false;
      }
      if (this.configData.cameraConfigs == null) {
        return false;
      }
      if (this.usedId == null) {
        return false;
      }
      if (this.cameraConfig == null) {
        return false;
      }
      return true;
    }
  },
  watch: {
    selectedCamera () {
      if (this.selectedCamera == null)
      {
        return;
      }
      else
      {
        this.cameraConfig.ipAddress = this.selectedCamera.ipAddress;
        this.cameraConfig.manufacturer = this.selectedCamera.manufacturer;
        this.cameraConfig.model = this.selectedCamera.model;
      }
    }
  },
  async created () {
    if (this.currentId == null) {
      this.usedId = 1;
    } else {
      this.usedId = this.currentId;
    }
    await this.getAvailableCameras();
    this.getConfig();
  },
  methods: {
    setConfig () {
      let url = `/Config/UpdateCameraManagementConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, this.configData)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 204) {
            this.$emit('hide');
            this.$emit('setReloadButton', true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.$emit('setReloadButton', false);
            if (response.status != 200) {
              return;
            }
          }

          this.$snotify.success(this.$t('cameraManagementConfigFormComponent.successfullySaved'));
          this.error_clear();
          this.$emit('getConfig');
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    getConfig () {
      let url = `/Config/GetCameraManagementConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return
          }
          this.configData = response.data;
          this.selectedCamera = this.networkCameras.find(p => p.ipAddress == this.cameraConfig.ipAddress);
        })
        .finally(() => {
          if (this.configData != null) {
            this.loading = false;
          } else {
            this.getDefaultConfig();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async getAvailableCameras () {
     let url = `/DeviceManagement/GetDevices?installationId=${ this.installationId }`; 
     await this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return
          }
          this.networkCameras = response.data.filter(p => p.discoveredDeviceType == "camera");
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDefaultConfig () {
      let url = `/Config/GetDefaultCameraManagementConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.cameraConfigs == null) {
            return;
          }
          this.configData = response.data;
        })
        .finally(() => {
          this.updateButtonText = 'Add';
          this.updateButtonIcon = 'plus';
          this.loading = false;
        });
      }
  }
}
</script>